.header {
  width: 100%;
  height: $header_height;
  min-height: $header_height;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $line_gray;
  padding: 0 24px;
  position: sticky;
  top: 0;
  z-index: $header-z-in;
  background-color: $white;

  .left {
    .logo {
      display: block;
      width: 112px;
      height: 20.64px;
      background-image: url("../../images/logo_taapspace.svg");
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
  .right {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    justify-content: flex-end;
    .search {
      width: 30px;
      height: 30px;
      background-image: url("../../images/icons_medium_search.svg");
      background-repeat: no-repeat;
      background-size: contain;
      margin-right: 20px;
      cursor: pointer;
    }
    .my {
      width: 30px;
      height: 30px;
      background-image: url("../../images/icons_large_my.svg");
      background-repeat: no-repeat;
      background-size: contain;
      cursor: pointer;
    }
    .active::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 2px;
      background-color: $text_primary1;
      bottom: -14px;
    }
  }
}

.select-header-wrap {
  // [select] 백버튼 있고 센터에 셀렉트 있는 헤더
  width: 100%;
  height: $backward_header_height;
  min-height: $backward_header_height;
  position: sticky;
  top: 0;
  z-index: $header-z-in;
  background-color: $white;
  .select-header {
    width: 100%;
    height: $backward_header_height;
    padding: 0 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    space p {
      font-size: 1.5rem;
      line-height: 22px;
    }
    &__left {
      display: flex;
      align-items: center;
      // width: 100%;
      width: fit-content;
      padding-right: 30px;
    }

    &__right {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      // width: 100%;
      width: fit-content;
      padding-left: 30px;
    }
  }
}

.backward-wrap {
  height: 65px;
}
.backward-header {
  // [back] 백버튼만 있는 헤더
  width: 100%;
  z-index: $header-z-in;
  height: $backward_header_height;
  min-height: $backward_header_height;
  background-color: $white;
  position: sticky;
  top: 0;
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &__wrap {
    display: flex;
    align-items: center;
    width: calc(100% - 24px);
  }
  p {
    // width: 100%;
    font-size: 1.5rem;
    line-height: 22px;
    @include ellipsis;
  }
  .home {
    display: block;
    width: 30px;
    height: 30px;
    background-image: url("../../images/icons_large_lighthome.svg");
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
  }
}
.close-header {
  // [close] 클로즈 버튼만 있는 헤더. 기본은 센터 타이틀
  width: 100%;
  z-index: $header-z-in;
  height: $backward_header_height;
  min-height: $backward_header_height;
  background-color: $white;
  position: sticky;
  top: 0;
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  p {
    font-size: 1.5rem;
    line-height: 22px;
  }
  .bin {
    min-width: 30px;
    min-height: 30px;
    display: flex;
    align-items: center;
  }
  &.left-text {
    // 좌측타이틀
    justify-content: flex-start;
  }
}

.back-header-wrap {
  // 나중에 제거
  width: 100%;
  height: $backward_header_height;
  min-height: $backward_header_height;
  position: sticky;
  top: 0;
  z-index: $header-z-in;
  background-color: $white;
  .back-header {
    width: 100%;
    height: $backward_header_height;
    padding: 0 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    space p {
      font-size: 1.5rem;
      line-height: 22px;
    }
    &__left {
      display: flex;
      align-items: center;
      // width: 100%;
      width: fit-content;
      padding-right: 30px;
    }

    &__right {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      // width: 100%;
      width: fit-content;
      padding-left: 30px;
    }
  }
}
.drop-shadow {
  filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.05));
}
