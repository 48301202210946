html {
  height: 100%;
  font-size: 10px;
}
body {
  padding: 0;
  margin: 0;
  height: 100%;
  background-color: $white;
  width: 100%;
  overflow: hidden;
  font-family: "Apple SD Gothic Neo", sans-serif;
  word-break: break-all;
  -webkit-tap-highlight-color: transparent;
}

#root {
  height: 100%;
}
:root {
  --isOpenToTaap: false;
}
main {
  // height: calc(100% - $header_height);
  height: auto;
}
.with-backward-floating {
  min-height: $with-backward-floating;
}
.with-floating {
  min-height: $with-floating;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

a {
  color: $text-primary1;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
}
span,
p {
  letter-spacing: -0.04em;
}
button {
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: unset;
  font-family: "Apple SD Gothic Neo", sans-serif;
}
.w-100 {
  width: 100%;
}
.h-100 {
  height: 100%;
}
.w-fit {
  width: fit-content !important;
}
.d-none {
  display: none !important;
}
.d-none-pc {
  //pc에서 hide
}
.d-none-mo {
  display: none;
}
.d-block {
  display: block !important;
}
.d-flex {
  display: flex !important;
}
.flex-row {
  flex-direction: row !important;
}
.flex-start {
  display: flex;
  align-items: flex-start;
}
.flex-start-between {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.flex-center {
  display: flex;
  align-items: center;
}
.flex-end {
  display: flex;
  align-items: flex-end;
}
.flex-center-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-center-end {
  display: flex;
  align-items: center;
  justify-content: flex-end !important;
}
.flex-center-start {
  display: flex;
  align-items: center;
  justify-content: flex-start !important;
}
.flex-center-between {
  display: flex;
  align-items: center;
  justify-content: space-between !important;
}

.flex-direction-column {
  display: flex;
  flex-direction: column;
}

.list-none {
  list-style: none;
}
.font-weight-400 {
  font-weight: 400 !important;
}
.font-weight-500 {
  font-weight: 500 !important;
}
.font-weight-600 {
  font-weight: 600 !important;
}
.font-weight-700 {
  font-weight: 700 !important;
}

.font-family-roboto {
  @include font("roboto");
}

.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-underline {
  text-decoration: underline;
}
.text-decoration-none {
  text-decoration: none;
}
.text-line {
  text-decoration: line-through;
}
.no-wrap {
  white-space: nowrap !important;
}
.pre-line {
  white-space: pre-line;
}
.keep-all {
  word-break: keep-all;
}

.cursor-pointer {
  cursor: pointer;
}
.position-relative {
  position: relative;
}
.bg-temp {
  background-color: $bg_dark;
}
.lh-20 {
  line-height: 20px;
}

// 1줄 ... 처리
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// 2줄 ... 처리
.ellipsis2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  //필요에 따라 line-height를 써야할 수 있다.
}
.App {
  width: 100%;
  height: 100%;
}
.base-layout-wrap {
  width: 100%;
  height: 100%;
  @include desktop {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .pc-left {
    display: none;
    @include desktop {
      display: flex;
      justify-content: flex-end;
      padding-right: 50px;
      padding-left: 50px;
      &__layout {
        max-width: 430px;
        width: 100%;
        display: flex;
        flex-direction: column;
        &-top {
          flex: 1;
        }
        &-center {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          p {
            font-size: 1.6rem;
            color: $text-primary3;
            font-weight: 600;
          }
          h2 {
            font-size: 2.4rem;
            margin-top: 4px;
          }
          img {
            width: 245px;
            height: auto;
            margin-bottom: 40px;
          }
        }
        &-bottom {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          padding-bottom: 60px;
          .description {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            .app-icon {
              width: 56px;
              height: 56px;
              margin-right: 10px;
              background-image: url("../images/app_taap_b.svg");
              background-position: center;
              background-size: contain;
              background-repeat: no-repeat;
            }
            h3 {
              font-size: 1.8rem;
              font-weight: 600;
              line-height: 26px;
              margin-bottom: 4px;
            }
            p {
              font-size: 1.5rem;
              color: $text-primary3;
              font-weight: 400;
            }
          }
          .store-btn-wrap {
            display: flex;
            align-items: center;
            width: 100%;
            gap: 6px;
            button {
              cursor: pointer;
              min-width: 120px;
              border: none;
            }
            .download-appstore {
              background-image: url("../images/download_appstore.svg");
              background-repeat: no-repeat;
              background-position: left;
              background-size: contain;
              background-color: transparent;
              display: inline-block;
              height: 40px;
            }
            .download-googleplay {
              background-image: url("../images/download_googleplay.png");
              background-repeat: no-repeat;
              background-position: left;
              background-size: contain;
              background-color: transparent;
              display: inline-block;
              height: 40px;
              min-width: 132px;
            }
          }
        }
      }
    }
  }
}
.base-layout-container {
  width: 100%;
  max-width: 475px;
  height: 100%;
  margin: 0 auto;
  border-left: 1px solid $line_dark;
  border-right: 1px solid $line_dark;
  @include desktop {
    margin: unset;
  }
  @media (max-width: 475px) {
    border-left: unset;
    border-right: unset;
  }

  // background-color: #fff;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  &.full-case {
    margin: unset;
    max-width: unset;
    overflow: scroll;
  }
}
.base-title-wrap {
  display: none;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
  h1 {
    margin: 0;
  }
  .base-btn {
    width: 120px;
  }
}
.base-title {
  font-size: 3rem;
  margin-bottom: 50px;
  display: none;
}

.base-layout {
  width: 100%;
  height: 100%;
  display: flex;
  background-color: #fff;
  flex-direction: column;
  justify-content: space-between;
  .base-layout-contents {
    width: 100%;
    height: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    position: relative;
  }
}
.horizontal-divider {
  width: 100%;
  height: 1px;
  background-color: $line_light;
  margin: 20px 0;
}
.bottom-divider {
  position: relative;
  margin-bottom: 10px;
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    bottom: -10px;
    height: 8px;
    background-color: $bg_dark;
    left: 0;
  }
}
.top-divider {
  position: relative;
  margin-top: 10px;
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    top: -10px;
    height: 8px;
    background-color: $bg_dark;
    left: 0;
  }
}

.border-top {
  border-top: 1px solid $line_dark;
}
.border-bottom {
  border-bottom: 1px solid $line_dark;
}

.base-section-layout {
  padding: 0px 24px;
  &__container {
    padding-top: 30px;
    &.gap20 {
      // 사이 간격이 20일때 (default 30)
      .base-section-layout__wrap {
        padding-top: 20px;
        padding-bottom: 20px;
        // & ~ .base-section-layout__wrap {
        // }
        &:first-child {
          border-top: none;
          padding-top: 0;
        }
        &:last-child {
          padding-bottom: 30px;
        }
      }
      &.border-bottom-gray {
        //gap20, border-bottom-gray가 함께일때
        .base-section-layout__wrap {
          &:last-child {
            padding-bottom: 20px;
          }
        }
      }
    }
    &.border-bottom-gray {
      border-bottom: 1px solid $line_light;
    }
  }

  h2 {
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 22px;
    padding-bottom: 20px;
  }
  &__title-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;
    h2 {
      padding-bottom: 0px;
    }
  }
  &__wrap {
    border-top: 1px solid $line_dark;
    padding-top: 30px;
    padding-bottom: 30px;
    // & ~ .base-section-layout__wrap {
    // }
    &:first-child {
      border-top: none;
      padding-top: 0;
    }
    // &:last-child{
    //   margin-bottom:30px;
    // }
  }

  &-bottom-line {
    border-bottom: 1px solid $line_dark;
    padding-bottom: 30px;
    margin-bottom: 30px;
  }
  &-line {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
    &.left-case {
      justify-content: flex-start;
      align-items: flex-start;
      .base-section-layout-index {
        min-width: 83px;
      }
      .base-section-layout-value {
        width: 100%;
        word-wrap: wrap break-word;
        line-height: 25px;
        text-align: left;
      }
    }
  }
  &-index {
    font-size: 1.5rem;
    line-height: 22px;
    white-space: nowrap;
    .desc {
      font-size: 1.2rem;
      margin-left: 5px;
    }
  }
  &-value {
    font-size: 1.5rem;
    line-height: 22px;
    letter-spacing: unset;
    font-family: "Roboto", sans-serif;
    text-align: right;
    span {
      letter-spacing: unset;
    }
  }
  ul {
    // padding-top:20px;
    li {
      list-style: none;
      font-size: 1.4rem;
      line-height: 20px;
      letter-spacing: -0.04em;
      color: $text-primary3;
      position: relative;
      padding-left: 12px;
      margin-left: 6px;
      &:before {
        content: "·";
        position: absolute;
        left: 0;
        font-size: 20px;
        vertical-align: middle;
        line-height: 20px;
      }
    }
  }
}
//mo100% pc 342px fixed
.base-btn-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .base-btn-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
  }
}
.base-btn-wrap {
  padding-top: 80px;
  padding-bottom: 50px;
  padding-left: 24px;
  padding-right: 24px;
  display: flex;
  align-items: center;
}

.contents-detail {
  padding: 30px 24px;
  &__title {
    padding-bottom: 50px;
    border-bottom: 1px solid $line_light;
    h1 {
      font-weight: 700;
      font-size: 2.4rem;
      line-height: 3.4rem;
    }
    p {
      font-size: 1.4rem;
      color: $text-primary4;
      padding-top: 10px;
    }
  }
  &__contents {
    line-height: 20px;
    font-weight: 400;
    font-size: 1.4rem;
    white-space: pre-line;
  }
}
// empty-case
.contents-empty {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: $only_backward_header; //화면마다 다를경우 새로 정의
  p {
    font-size: 1.5rem;
    line-height: 22px;
    font-weight: 600;
    color: $text-primary1;
  }
}

.contents-indicator {
  padding: 30px;
}

.base-detail-layout {
  padding: 20px 24px 20px 24px;
  .title-wrap {
    h2 {
      margin-top: 10px;
      font-weight: 600;
      font-size: 1.8rem;
      line-height: 26px;
      letter-spacing: -0.72px;
      @include ellipsis(2);
    }
    span {
      font-size: 1.4rem;
      font-weight: 400;
      color: $text-primary1;
    }
    p {
      font-size: 1.3rem;
      line-height: 2rem;
      color: $text-primary4;
      padding-top: 4px;
      padding-bottom: 20px;
      @include font("roboto");
      @include ellipsis;
    }
  }

  .contents {
    &__section {
      padding-top: 20px;
      border-top: 1px solid $line_dark;
      font-size: 1.4rem;
      white-space: pre-line;
    }
  }
}
.bread-crumbs {
  padding-top: 50px;
  padding-bottom: 20px;
  font-size: 1.4rem;
  color: $text-primary3;
  a {
    color: $text-primary3;
    &:hover {
      color: $text-primary1;
    }
  }
  display: none;
}

.empty-bread-crumbs {
  padding-top: 50px;
  display: none;
}

.base-markdown {
  font-size: 1.5rem;
  white-space: initial;

  .toastui-editor-contents {
    ol > li::before {
      font-size: 1.5rem !important;
      margin-top: 1.5px;
      color: $text_primary1;
    }
    ul > li::before {
      margin-top: 9px;
      background-color: $text_primary1;
    }
    h1 {
      color: $text_primary1;
      span {
        font-size: 2.4rem !important;
        line-height: 2.8rem !important;
      }
    }
    h2 {
      color: $text_primary1;
      span {
        font-size: 2.2rem !important;
        line-height: 2.3rem !important;
      }
    }
    h3 {
      color: $text_primary1;
      span {
        font-size: 2rem !important;
      }
    }
    h4 {
      color: $text_primary1;
      span {
        font-size: 1.8rem !important;
      }
    }
    h5 {
      color: $text_primary1;
      span {
        font-size: 1.6rem !important;
      }
    }
    h6 {
      color: $text_primary1;
      span {
        font-size: 1.4rem !important;
      }
    }
    p {
      font-size: 1.5rem !important;
      color: $text_primary1;
      span {
        color: $text_primary1;
      }
    }
    table {
      color: $text-primary1;
    }
  }
}

.range-datepicker-input {
  display: flex;
  align-items: center;

  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 40px;

  background: #ffffff;
  border: 1px solid #e0e0e0;

  cursor: pointer;

  white-space: nowrap;
  box-sizing: border-box;

  border: none;
  border-bottom: 1px solid #dbdbdb;

  input {
    padding: 10px;
    border: none;
    flex: 1;
    height: 100%;

    background-color: transparent;
    outline: none;
    cursor: pointer;
  }

  &:hover {
    background-color: #f5f5f5;
  }

  .icon {
    width: 36px;
    height: 100%;
    box-sizing: border-box;
    background-image: url("../images/ic_calendar_b.svg");
    background-repeat: no-repeat;
    background-position: center;
  }
}

.pre-formatted {
  white-space: pre-wrap;
}

.date-chip {
  display: flex;
  align-items: center;
  justify-content: center;

  background: #f2f4f6;
  border: 1px solid #e0e0e0;
  border-radius: 4px;

  padding: 9px 0;

  width: 107px;
  font-size: 15px;

  cursor: pointer;
}

.date-chip.selected {
  background: #191919;
  color: #ffffff;
}

ul.base-list {
  li {
    list-style: none;
    font-size: 1.4rem;
    line-height: 20px;
    letter-spacing: -0.04em;
    color: $text-primary3;
    position: relative;
    padding-left: 12px;
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    letter-spacing: -0.04px;
    &:last-child {
      margin-bottom: 0;
    }
    &:before {
      content: "";
      width: 3px;
      height: 3px;
      border-radius: 8px;
      background-color: $text_primary3;
      position: absolute;
      left: 0;
      top: 9px;
    }
  }
}

// 검정색 체크박스
input[type="checkbox"].checkbox {
  display: none;
}

input[type="radio"].radio {
  display: none;
}

.map-address {
  font-size: 1.4rem;
  color: $text-primary3;
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.map-address-copy {
  display: block;
  font-size: 1.2rem;
  color: $text-primary3;
  text-decoration: underline;
  cursor: pointer;
}

.grecaptcha-badge {
  visibility: hidden;
}

.base-file {
  &__list {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  &__download {
    display: block;
    width: 20px !important;
    height: 20px;
    background-image: url("../images/ic_download_g.svg");
    background-repeat: no-repeat;
    background-size: contain;
  }
  &__remove {
    width: 20px;
    height: 20px;
    border: none;
    background-color: transparent;
    background-image: url("../images/ic_trash_g.svg");
    background-repeat: no-repeat;
    background-size: contain;
  }
  .filename {
    width: fit-content;
    max-width: 310px;
    overflow: hidden;
    @include ellipsis;
  }
}
.base-image {
  margin: 0 !important;
  width: 100% !important;
  border-radius: 0 !important;
}
.base-image__full-screen {
  img {
    width: 100%;
    display: block;
  }
  .file-name {
    text-align: center;
    font-size: 15px;
    padding-bottom: 20px;
  }
}
